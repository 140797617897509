import React, { useRef, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Container from "../ui/Container";


const HeroSection = () => {
  const videoRefs = useRef([]);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  const videoSources = [
    { name: "Services", src: "/assets/images/nifi_services_part1_v1.mp4" },
    { name: "Analyzer Tool", src: "/assets/images/nifi_services_part2_v1.mp4" }
  ];

  // Function to switch to the next video
  const nextVideo = () => {
    setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videoSources.length);
  };

  // Automatically rotate videos every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      nextVideo();
    }, 12000); // Change video every 5 seconds

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  // Play the current video when the index changes
  useEffect(() => {
    videoRefs.current[currentVideoIndex].play();
  }, [currentVideoIndex]);

  // Handle manual video selection
  const handleVideoSelect = (index) => {
    setCurrentVideoIndex(index);
  };

  return (
    <section>
      <div className="relative isolate overflow-hidden bg-gradient-to-b from-indigo-100/20 pt-14">
        <div
          className="absolute inset-y-0 right-1/2 -z-10 -mr-96 w-[200%] origin-top-right skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:-mr-80 lg:-mr-96"
          aria-hidden="true"
        />
        <Container>
          <div className="py-10 md:py-20">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8">
              <h1 className="max-w-2xl text-4xl font-medium tracking-tight text-gray-900 sm:leading-tight sm:text-6xl lg:col-span-2 xl:col-auto">
                Enable innovation through data integration<br />
              </h1>
              <div className="mt-6 max-w-xl lg:mt-0 xl:col-end-1 xl:row-start-1">
                <p className="text-lg leading-8 text-gray-600">
                  Drive innovation by effortlessly orchestrating, enhancing
                  and transforming your data flows.
                </p>
                <p className="mt-2 text-lg leading-8 text-gray-600">
                  With our AI-driven insights, optimize, secure and visualize your environment and data flows.
                </p>
                <div className="mt-10 flex items-center gap-x-6">
                  <Link
                    to="/analyze-database"
                    className="flex items-center gap-2 rounded-md px-8 py-3 text-xl text-white font-semibold btn-primary"
                  >
                    <span>Free NiFi Flow Analysis</span>
                  </Link>
                </div>
              </div>
              <div className="hidden lg:block mt-10 aspect-[6/5] w-full max-w-lg rounded-2xl object-cover sm:mt-16 lg:mt-0 lg:max-w-none xl:row-span-2 xl:row-end-2 xl:mt-20">
                {/* Video Player */}
                <div className="relative h-full w-full">
                  {videoSources.map((video, index) => (
                    <video
                      key={video.src}
                      ref={(el) => (videoRefs.current[index] = el)}
                      className="h-full w-full object-cover rounded-3xl"
                      style={{ display: index === currentVideoIndex ? 'block' : 'none' }}
                      src={video.src}
                      muted
                      loop
                    />
                  ))}
                </div>

                {/* Video Selection Buttons */}
                <div className="mt-4 flex justify-center space-x-4">
                  {videoSources.map((video, index) => (
                    <button
                      key={video.src}
                      onClick={() => handleVideoSelect(index)}
                      className={`px-4 py-2 rounded-lg ${
                        index === currentVideoIndex
                          ? 'bg-blue-500 text-white'
                          : 'bg-gray-200 text-gray-800'
                      }`}
                    >
                      {video.name}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Container>
        <div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32" />
      </div>
    </section>
  );
};

export default HeroSection;

